<template>
  <b-card-normal title="İletişim Bilgi" :showLoading="show">
    <template v-slot:body>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
          <b-row>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Başlık">
                      <b-form-input
                        class="rounded-0"
                        ref="baslik"
                        v-model="form.baslik"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Adres" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Adres">
                      <b-form-textarea
                        class="rounded-0"
                        ref="adres"
                        v-model="form.adres"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Sabit Telefon">
                    <b-form-input class="rounded-0" ref="sabit_telefon" v-model="form.sabit_telefon" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Cep Telefon">
                    <b-form-input class="rounded-0" ref="cep_telefon" v-model="form.cep_telefon" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Fax ">
                    <b-form-input class="rounded-0" ref="fax_telefon" v-model="form.fax_telefon" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="E Mail">
                    <b-form-input class="rounded-0" ref="e_mail" v-model="form.e_mail" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Yol Tarihi Link">
                    <b-form-input class="rounded-0" ref="yol_tarifi" v-model="form.yol_tarifi" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="Harita Url">
                        <b-form-input class="rounded-0" ref="harita" v-model="form.harita" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <a
                        href="https://yandex.com/map-constructor/?from=usermap&ll=30.346703,36.500140&z=9"
                        target="_blank"
                        type="submit"
                        block
                        class="btn btn-primary d-block rounded-0 mr-1"
                      >
                        <feather-icon icon="MapIcon" class="mr-50" />
                        <span class="align-middle">Yandex Harita ( 100% x 280px )</span>
                      </a>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col cols="12">
                      <b-alert variant="primary" class="text-center rounded-0" show v-if="!form.harita">
                        <h4 class="alert-heading">Bilgilendirme</h4>
                        <div class="alert-body">
                          Üst buttona tıkayarak konum bilgisi alabilirsiniz. Lütfen iframe linkini alınız.
                        </div>
                      </b-alert>
                      <div v-else v-html="form.harita"></div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-card-footer>
            <b-button variant="primary" size="lg" type="submit" class="rounded-0 float-right">
              <feather-icon class="mr-25" icon="SaveIcon" size="24" v-if="!show" />
              <b-spinner class="mr-25" small variant="light" v-else />
              <span class="align-middle">Kaydet</span>
            </b-button>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </template>
  </b-card-normal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    BCardNormal,
  },
  data: () => ({
    show: false,
    form: {
      _id: null,
      baslik: null,
      adres: null,
      sabit_telefon: null,
      cep_telefon: null,
      fax_telefon: null,
      e_mail: null,
      yol_tarifi: null,
      harita: null,
    },
  }),
  computed: {
    ...mapGetters(['getIletisim']),
  },
  created() {
    this.handlerGetData();
  },
  methods: {
    async handlerGetData() {
      this.show = true;
      await this.$store.dispatch('iletisimGetir').then((res) => {
        if (res.data.data !== null) {
          this.form = res.data.data;
        }
        this.show = false;
      });
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.$store
        .dispatch('iletisimEkle', this.form)
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: this.form._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
              },
            });
            this.show = false;
          }
        })
        .catch((err) => {
          this.show = false;
          if (err.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },
  },
};
</script>

<style lang="scss"></style>
